import { EventbookingActionTypes } from './constants';

export const listEventbookings = (actionType: string, data: any): EventbookingAction => ({
    type: EventbookingActionTypes.LIST_EVENTBOOKING,
    payload: { data },
});

export const filterEventbookings = (data: any): EventbookingAction => ({
    type: EventbookingActionTypes.FILTER_EVENTBOOKING,
    payload: { data },
});

export const createEventbookingComment = (data, id): EventbookingAction => ({
    type: EventbookingActionTypes.CREATE_COMMENT,
    payload: { data, id},
});

export const updateEventbookingComment = (data, id, eventbooking_id) => ({
    type: EventbookingActionTypes.UPDATE_COMMENT,
    payload: { data, id, eventbooking_id },
});

export const deleteEventbookingComment = (id, eventbooking_id) => ({
    type: EventbookingActionTypes.DELETE_COMMENT,
    payload: { id, eventbooking_id },
});

export const showEventbookings = (data: any): EventbookingAction => ({
    type: EventbookingActionTypes.SHOW_EVENTBOOKING,
    payload: { data },
});

// common success
export const authApiResponseSuccess = (actionType: string, data: any): EventbookingAction => ({
    type: EventbookingActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): EventbookingAction => ({
    type: EventbookingActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

