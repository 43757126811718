// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    eventList as eventListApi,
    eventShow as eventShowApi,
    eventFilter as eventFilterApi
} from "../../../helpers/api/event"

import {
    authApiResponseSuccess,
    authApiResponseError,
} from "./actions"
import { EventActionTypes } from './constants';


function* list() {
    try {
        const response = yield call(eventListApi);
        console.log(response)
        const events = response.data
        // NOTE - You can change this according to response format from your api
        yield put(authApiResponseSuccess(EventActionTypes.LIST_EVENT, events));
    } catch (error) {
        console.log(error)
        yield put(authApiResponseError(EventActionTypes.LIST_EVENT, error));
    }
}

function* filter({ payload: { data } }) {
    try {
        console.log(data)
        const response = yield call(eventFilterApi, { data });
        const events = response.data;

        yield put(authApiResponseSuccess(EventActionTypes.FILTER_EVENT, events));
    } catch (error) {
        yield put(authApiResponseError(EventActionTypes.FILTER_EVENT, error));
    }
}

function* show({ payload: { data } }) {
    try {
        const response = yield call(eventShowApi, data);
        const event = response.data.event;

        yield put(authApiResponseSuccess(EventActionTypes.SHOW_EVENT, event));
    } catch (error) {
        yield put(authApiResponseError(EventActionTypes.SHOW_EVENT, error));
    }
}

export function* watchEventList(): any {
    yield takeEvery(EventActionTypes.LIST_EVENT, list);
}

export function* watchEventFilter(): any {
    yield takeEvery(EventActionTypes.FILTER_EVENT, filter);
}

export function* watchEventShow(): any {
    yield takeEvery(EventActionTypes.SHOW_EVENT, show);
}


function* eventSaga(): any {
    yield all([
        fork(watchEventList),
        fork(watchEventShow),
        fork(watchEventFilter)
    ]);
}

export default eventSaga;
