import { APICore } from './apiCore';

const api = new APICore();

function digitalProductList(): any {
    const baseUrl = '/eventbookings/digital_products';
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}


function digitalProductShow(params: any): any {
    const baseUrl = '/eventbookings/digital_products/' + params;
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

function digitalProductFilter(params: any): any {
    const baseUrl = '/eventbookings/digital_products';
    console.log(baseUrl)
    return api.get(`${baseUrl}`, params,{ headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

export { digitalProductList, digitalProductShow, digitalProductFilter };