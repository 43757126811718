import { APICore } from './apiCore';

const api = new APICore();

function bookingRequestList(): any {
    const baseUrl = '/eventbookings/booking_requests';
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}


function bookingRequestShow(params: any): any {
    const baseUrl = '/eventbookings/' + params;
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

function bookingRequestUpdate(requestBody) {
    const baseUrl = '/eventbookings/' + requestBody.id + '/confirm_booking_request';
    return api.update(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function bookingRequestCreateComment(requestBody) {
    const baseUrl = '/eventbookings/' + requestBody.id + '/eventbooking_comments';
    return api.create(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function bookingRequestFilter(params: any): any {
    const baseUrl = '/eventbookings/booking_requests';

    let new_query_array = []
    Object.keys(params["data"]).map(function(key) {
        new_query_array.push(`q[${key}]=${params["data"][key]}`)
    });

    return api.get(`${baseUrl}?${new_query_array.join('&')}`, params,{ headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

export { bookingRequestList, bookingRequestShow, bookingRequestFilter, bookingRequestUpdate, bookingRequestCreateComment };