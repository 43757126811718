import { BusinessdocumentActionTypes } from './constants';

export const listBusinessdocuments = (actionType: string, data: any): BusinessdocumentAction => ({
    type: BusinessdocumentActionTypes.LIST_BUSINESSDOCUMENT,
    payload: { data },
});

export const filterBusinessdocuments = (data: any): BusinessdocumentAction => ({
    type: BusinessdocumentActionTypes.FILTER_BUSINESSDOCUMENT,
    payload: { data },
});

export const showBusinessdocuments = (data: any): BusinessdocumentAction => ({
    type: BusinessdocumentActionTypes.SHOW_BUSINESSDOCUMENT,
    payload: { data },
});

// common success
export const authApiResponseSuccess = (actionType: string, data: any): BusinessdocumentAction => ({
    type: BusinessdocumentActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): BusinessdocumentAction => ({
    type: BusinessdocumentActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});
