import { BookingRequestActionTypes } from './constants';

export const listBookingrequests = (actionType: string, data: any): BookingrequestAction => ({
    type: BookingRequestActionTypes.LIST_BOOKING_REQUEST,
    payload: { data },
});

export const filterBookingrequests = (data: any): BookingrequestAction => ({
    type: BookingRequestActionTypes.FILTER_BOOKING_REQUEST,
    payload: { data },
});

export const confirmBookingRequest = (data, id): BookingrequestAction => ({
    type: BookingRequestActionTypes.CONFIRM_BOOKING_REQUEST,
    payload: { data, id},
});

export const createComment = (data, id): BookingrequestAction => ({
    type: BookingRequestActionTypes.CREATE_COMMENT,
    payload: { data, id},
});

export const showBookingrequests = (data: any): BookingrequestAction => ({
    type: BookingRequestActionTypes.SHOW_BOOKING_REQUEST,
    payload: { data },
});

// common success
export const authApiResponseSuccess = (actionType: string, data: any): BookingrequestAction => ({
    type: BookingRequestActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): BookingrequestAction => ({
    type: BookingRequestActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

