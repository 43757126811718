import { EventActionTypes } from './constants';

export const listEvents = (actionType: string, data: any): EventAction => ({
    type: EventActionTypes.LIST_EVENT,
    payload: { data },
});

export const filterEvents = (data: any): EventAction => ({
    type: EventActionTypes.FILTER_EVENT,
    payload: { data },
});

export const showEvents = (data: any): EventAction => ({
    type: EventActionTypes.SHOW_EVENT,
    payload: { data },
});

// common success
export const authApiResponseSuccess = (actionType: string, data: any): EventAction => ({
    type: EventActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): EventAction => ({
    type: EventActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

