// @flow
import { APICore } from './apiCore';

const api = new APICore();

// account
function login(params: any): any {
    const baseUrl = '/login';
    return api.create(`${baseUrl}`, params, { headers: { 'Content-Type': 'application/json', Accept: "*/*" } });
}

function logout(): any {
    const baseUrl = '/logout';
    return api.delete(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

function forgotPassword(params: any): any {
    const baseUrl = '/forgot';
    return api.create(`${baseUrl}`, params);
}

function forgotPasswordConfirm(params: any): any {
    const baseUrl = '/reset_password?reset_token=' + params.reset_password_token;
    return api.create(`${baseUrl}`, { password: params.password, password_confirmation: params.password_confirmation });
}


export { login, logout, forgotPassword, forgotPasswordConfirm };
