import { BusinessdocumentActionTypes } from './constants';

const BUSINESSDOCUMENT_STATE = {
    businessdocuments: [],
    businessdocument: {},
    total_pages: 1,
    filters: { },
    loading: false,
};

type BusinessdocumentAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { businessdocuments?: {} | null, loading?: boolean, +value?: boolean };

const BusinessdocumentReducer = (state: State = BUSINESSDOCUMENT_STATE, action: BusinessdocumentAction): any => {
    switch (action.type) {
        case BusinessdocumentActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BusinessdocumentActionTypes.LIST_BUSINESSDOCUMENT: {
                    return {
                        ...state,
                        businessdocuments: action.payload.data.businessdocuments,
                        total_pages: action.payload.data.total_pages,
                        loading: false,
                    };
                }

                case BusinessdocumentActionTypes.SHOW_BUSINESSDOCUMENT: {
                    return {
                        ...state,
                        businessdocument: action.payload.data,
                        loading: false,
                    };
                }
                case BusinessdocumentActionTypes.FILTER_BUSINESSDOCUMENT: {
                    return {
                        ...state,
                        businessdocuments: action.payload.data.businessdocuments,
                        total_pages: action.payload.data.total_pages,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case BusinessdocumentActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case BusinessdocumentActionTypes.LIST_BUSINESSDOCUMENT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        businessdocuments: [],
                        total_pages: 1,
                        loading: false,
                    };
                }
                case BusinessdocumentActionTypes.SHOW_BUSINESSDOCUMENT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        businessdocument: "",
                        loading: false,
                    };
                }
                case BusinessdocumentActionTypes.FILTER_BUSINESSDOCUMENT: {
                    return {
                        ...state,
                        businessdocuments: action.payload.data,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case BusinessdocumentActionTypes.LIST_BUSINESSDOCUMENT:
            return { ...state, loading: true, businessdocuments: [], total_pages: 1 };
        case BusinessdocumentActionTypes.SHOW_BUSINESSDOCUMENT:
            return { ...state, loading: true, businessdocument: "" };
        case BusinessdocumentActionTypes.FILTER_BUSINESSDOCUMENT:
            return { ...state, loading: true, businessdocuments: state.businessdocuments, filters: state.filters };
        default:
            return { ...state };
    }
};

export default BusinessdocumentReducer;