// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    businessdocumentList as businessdocumentListApi,
    businessdocumentShow as businessdocumentShowApi,
    businessdocumentFilter as businessdocumentFilterApi
} from "../../../helpers/api/businessdocument"

import {
    authApiResponseSuccess,
    authApiResponseError,
} from "./actions"
import { BusinessdocumentActionTypes } from './constants';


function* list() {
    try {
        const response = yield call(businessdocumentListApi);
        const businessdocuments = response.data
        // NOTE - You can change this according to response format from your api
        yield put(authApiResponseSuccess(BusinessdocumentActionTypes.LIST_BUSINESSDOCUMENT, businessdocuments));
    } catch (error) {
        console.log(error)
        yield put(authApiResponseError(BusinessdocumentActionTypes.LIST_BUSINESSDOCUMENT, error));
    }
}

function* filter({ payload: { data } }) {
    try {

        const response = yield call(businessdocumentFilterApi, { data });
        const businessdocuments = response.data;

        yield put(authApiResponseSuccess(BusinessdocumentActionTypes.FILTER_BUSINESSDOCUMENT, businessdocuments));
    } catch (error) {
        yield put(authApiResponseError(BusinessdocumentActionTypes.FILTER_BUSINESSDOCUMENT, error));
    }
}

function* show({ payload: { data } }) {
    try {
        const response = yield call(businessdocumentShowApi, data);
        const businessdocument = response.data.businessdocument;

        yield put(authApiResponseSuccess(BusinessdocumentActionTypes.SHOW_BUSINESSDOCUMENT, businessdocument));
    } catch (error) {
        yield put(authApiResponseError(BusinessdocumentActionTypes.SHOW_BUSINESSDOCUMENT, error));
    }
}

export function* watchBusinessdocumentList(): any {
    yield takeEvery(BusinessdocumentActionTypes.LIST_BUSINESSDOCUMENT, list);
}

export function* watchBusinessdocumentFilter(): any {
    yield takeEvery(BusinessdocumentActionTypes.FILTER_BUSINESSDOCUMENT, filter);
}

export function* watchBusinessdocumentShow(): any {
    yield takeEvery(BusinessdocumentActionTypes.SHOW_BUSINESSDOCUMENT, show);
}


function* eventbookingSaga(): any {
    yield all([
        fork(watchBusinessdocumentList),
        fork(watchBusinessdocumentShow),
        fork(watchBusinessdocumentFilter)
    ]);
}

export default eventbookingSaga;
