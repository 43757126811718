// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    eventbookingList as eventbookingListApi,
    eventbookingShow as eventbookingShowApi,
    eventbookingFilter as eventbookingFilterApi,
    eventbookingCreateComment as eventbookingCreateCommentApi,
    eventbookingUpdateComment as eventbookingUpdateCommentApi,
    eventbookingDeleteComment as eventbookingDeleteCommentApi
} from "../../../helpers/api/eventbooking"

import {
    authApiResponseSuccess,
    authApiResponseError,
} from "./actions"
import { EventbookingActionTypes } from './constants';


function* list() {
    try {
        const response = yield call(eventbookingListApi);
        const eventbookings = response.data
        // NOTE - You can change this according to response format from your api
        yield put(authApiResponseSuccess(EventbookingActionTypes.LIST_EVENTBOOKING, eventbookings));
    } catch (error) {
        console.log(error)
        yield put(authApiResponseError(EventbookingActionTypes.LIST_EVENTBOOKING, error));
    }
}

function* filter({ payload: { data } }) {
    try {
        console.log(data)
        const response = yield call(eventbookingFilterApi, { data });
        const eventbookings = response.data;

        yield put(authApiResponseSuccess(EventbookingActionTypes.FILTER_EVENTBOOKING, eventbookings));
    } catch (error) {
        yield put(authApiResponseError(EventbookingActionTypes.FILTER_EVENTBOOKING, error));
    }
}

function* createComment(action) {

    try {
        const response = yield call(eventbookingCreateCommentApi, {id: action.payload.id, data: action.payload.data});


        yield put(authApiResponseSuccess(EventbookingActionTypes.CREATE_COMMENT, {eventbooking: response.data.data.eventbooking, id: action.payload.data }));
    } catch (error) {
        yield put(authApiResponseError(EventbookingActionTypes.CREATE_COMMENT, error));
    }
}

function* updateComment(action) {

    try {
        const response = yield call(eventbookingUpdateCommentApi, {id: action.payload.id, eventbooking_id: action.payload.eventbooking_id, data: action.payload.data});


        yield put(authApiResponseSuccess(EventbookingActionTypes.UPDATE_COMMENT, {id: action.payload.data, eventbooking: response.data.data.eventbooking }));
    } catch (error) {
        yield put(authApiResponseError(EventbookingActionTypes.UPDATE_COMMENT, error));
    }
}

function* deleteComment(action) {

    try {
        const response = yield call(eventbookingDeleteCommentApi, {id: action.payload.id, eventbooking_id: action.payload.eventbooking_id});

        yield put(authApiResponseSuccess(EventbookingActionTypes.DELETE_COMMENT, {id: action.payload.data, eventbooking: response.data.data.eventbooking }));
    } catch (error) {
        yield put(authApiResponseError(EventbookingActionTypes.DELETE_COMMENT, error));
    }
}

function* show({ payload: { data } }) {
    try {
        const response = yield call(eventbookingShowApi, data);
        const eventbooking = response.data.eventbooking;

        yield put(authApiResponseSuccess(EventbookingActionTypes.SHOW_EVENTBOOKING, eventbooking));
    } catch (error) {
        yield put(authApiResponseError(EventbookingActionTypes.SHOW_EVENTBOOKING, error));
    }
}

export function* watchEventbookingList(): any {
    yield takeEvery(EventbookingActionTypes.LIST_EVENTBOOKING, list);
}

export function* watchEventbookingFilter(): any {
    yield takeEvery(EventbookingActionTypes.FILTER_EVENTBOOKING, filter);
}

export function* watchEventbookingShow(): any {
    yield takeEvery(EventbookingActionTypes.SHOW_EVENTBOOKING, show);
}

export function* watchEventbookingCreateComment(): any {
    yield takeEvery(EventbookingActionTypes.CREATE_COMMENT, createComment);
}

export function* watchEventbookingUpdateComment(): any {
    yield takeEvery(EventbookingActionTypes.UPDATE_COMMENT, updateComment);
}

export function* watchEventbookingDeleteComment(): any {
    yield takeEvery(EventbookingActionTypes.DELETE_COMMENT, deleteComment);
}


function* eventbookingSaga(): any {
    yield all([
        fork(watchEventbookingList),
        fork(watchEventbookingShow),
        fork(watchEventbookingFilter),
        fork(watchEventbookingCreateComment),
        fork(watchEventbookingUpdateComment),
        fork(watchEventbookingDeleteComment)
    ]);
}

export default eventbookingSaga;
