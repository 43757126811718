
export const EventbookingActionTypes = {
    API_RESPONSE_SUCCESS: '@@eventbooking/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@eventbooking/API_RESPONSE_ERROR',

    LIST_EVENTBOOKING: '@@eventbooking/LIST_EVENTBOOKING',
    SHOW_EVENTBOOKING: '@@eventbooking/SHOW_EVENTBOOKING',
    CREATE_COMMENT: '@@eventbooking/CREATE_COMMENT',
    UPDATE_COMMENT: '@@eventbooking/UPDATE_COMMENT',
    DELETE_COMMENT: '@@eventbooking/DELETE_COMMENT',
    FILTER_EVENTBOOKING: '@@eventbooking/FILTER_EVENTBOOKING',
};