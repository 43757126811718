import { EventCoachActionTypes } from './constants';
import {BookingRequestActionTypes} from "../booking_requests/constants";

export const listEventCoaches = (actionType: string, data: any): EventCoachAction => ({
    type: EventCoachActionTypes.LIST_EVENT_COACH,
    payload: { data },
});

export const filterEventCoaches = (data: any): EventCoachAction => ({
    type: EventCoachActionTypes.FILTER_EVENT_COACH,
    payload: { data },
});

export const showEventCoach = (data: any): EventCoachAction => ({
    type: EventCoachActionTypes.SHOW_EVENT_COACH,
    payload: { data },
});

export const updateEventCoach = (data, id): EventCoachAction => ({
    type: EventCoachActionTypes.UPDATE_EVENT_COACH,
    payload: { data, id},
});

// common success
export const authApiResponseSuccess = (actionType: string, data: any): EventCoachAction => ({
    type: EventCoachActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): EventCoachAction => ({
    type: EventCoachActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

