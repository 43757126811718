// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    dashboardList as dashboardListApi
} from "../../../helpers/api/dashboard"

import {
    authApiResponseSuccess,
    authApiResponseError,
} from "./actions"
import { DashboardActionTypes } from './constants';


function* list() {
    try {
        const response = yield call(dashboardListApi);
        const dashboards = response.data
        // NOTE - You can change this according to response format from your api

        yield put(authApiResponseSuccess(DashboardActionTypes.LIST_DASHBOARD, dashboards));
    } catch (error) {
        console.log(error)
        yield put(authApiResponseError(DashboardActionTypes.LIST_DASHBOARD, error));
    }
}

export function* watchDashboardList(): any {
    yield takeEvery(DashboardActionTypes.LIST_DASHBOARD, list);
}


function* dashboardSaga(): any {
    yield all([
        fork(watchDashboardList)
    ]);
}

export default dashboardSaga;
