
export const BookingRequestActionTypes = {
    API_RESPONSE_SUCCESS: '@@booking_request/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@booking_request/API_RESPONSE_ERROR',

    LIST_BOOKING_REQUEST: '@@booking_request/LIST_BOOKING_REQUEST',
    SHOW_BOOKING_REQUEST: '@@booking_request/SHOW_BOOKING_REQUEST',
    CONFIRM_BOOKING_REQUEST: '@@booking_request/CONFIRM_BOOKING_REQUEST',
    CREATE_COMMENT: '@@booking_request/CREATE_COMMENT',
    DELETE_COMMENT: '@@booking_request/DELETE_COMMENT',
    FILTER_BOOKING_REQUEST: '@@booking_request/FILTER_BOOKING_REQUEST',
};