import { SurveyActionTypes } from './constants';

export const listSurveys = (actionType: string, data: any): SurveyAction => ({
    type: SurveyActionTypes.LIST_SURVEY,
    payload: { data },
});

export const filterSurveys = (data: any): SurveyAction => ({
    type: SurveyActionTypes.FILTER_SURVEY,
    payload: { data },
});

export const showSurveys = (data: any): SurveyAction => ({
    type: SurveyActionTypes.SHOW_SURVEY,
    payload: { data },
});

// common success
export const authApiResponseSuccess = (actionType: string, data: any): SurveyAction => ({
    type: SurveyActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): SurveyAction => ({
    type: SurveyActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

