import { AssetActionTypes } from './constants';

export const listAssets = (actionType: string, data: any): AssetAction => ({
    type: AssetActionTypes.LIST_ASSET,
    payload: { data },
});

export const filterAssets = (data: any): AssetAction => ({
    type: AssetActionTypes.FILTER_ASSET,
    payload: { data },
});

export const showAssets = (data: any): AssetAction => ({
    type: AssetActionTypes.SHOW_ASSET,
    payload: { data },
});

// common success
export const authApiResponseSuccess = (actionType: string, data: any): AssetAction => ({
    type: AssetActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): AssetAction => ({
    type: AssetActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

