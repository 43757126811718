import { DigitalProductActionTypes } from './constants';

const ASSET_STATE = {
    digital_products: [],
    digital_product: {},
    total_pages: 1,
    filters: { },
    loading: false,
};

type DigitalProductAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { survey?: {} | null, loading?: boolean, +value?: boolean };

const DigitalProductReducer = (state: State = ASSET_STATE, action: DigitalProductAction): any => {
    switch (action.type) {
        case DigitalProductActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DigitalProductActionTypes.LIST_DIGITAL_PRODUCT: {
                    return {
                        ...state,
                        digital_products: action.payload.data.digital_products,
                        total_pages: action.payload.data.total_pages,
                        loading: false,
                    };
                }
                case DigitalProductActionTypes.SHOW_DIGITAL_PRODUCT: {
                    return {
                        ...state,
                        digital_product: action.payload.data,
                        loading: false,
                    };
                }
                case DigitalProductActionTypes.FILTER_DIGITAL_PRODUCT: {
                    return {
                        ...state,
                        digital_products: action.payload.data.digital_products,
                        total_pages: action.payload.data.total_pages,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case DigitalProductActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DigitalProductActionTypes.LIST_DIGITAL_PRODUCT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        digital_products: [],
                        total_pages: 1,
                        loading: false,
                    };
                }
                case DigitalProductActionTypes.SHOW_DIGITAL_PRODUCT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        digital_product: "",
                        loading: false,
                    };
                }
                case DigitalProductActionTypes.FILTER_DIGITAL_PRODUCT: {
                    return {
                        ...state,
                        digital_products: action.payload.data,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case DigitalProductActionTypes.LIST_DIGITAL_PRODUCT:
            return { ...state, loading: true, digital_products: [], total_pages: 1 };
        case DigitalProductActionTypes.SHOW_DIGITAL_PRODUCT:
            return { ...state, loading: true, digital_product: "" };
        case DigitalProductActionTypes.FILTER_DIGITAL_PRODUCT:
            return { ...state, loading: true, digital_products: state.digital_products, filters: state.filters };
        default:
            return { ...state };
    }
};

export default DigitalProductReducer;