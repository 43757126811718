// @flow
import React from 'react';
import Routes from './routes/Routes';
import { BrowserRouter as Router } from 'react-router-dom';

// Themes

// For Saas import Saas.scss
import './assets/scss/Saas.scss';
import i18n from "i18next";

export const baseUrl = i18n.language === 'en' ? '' : '/'+i18n.language;

type AppProps = {};

/**
 * Main app component
 */
const App = (props: AppProps): React$Element<any> => {
  return <Routes>
    <Router basename={baseUrl}></Router>
  </Routes>;
};

export default App;

