// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    assetList as assetListApi,
    assetShow as assetShowApi,
    assetFilter as assetFilterApi
} from "../../../helpers/api/asset"

import {
    authApiResponseSuccess,
    authApiResponseError,
} from "./actions"
import { AssetActionTypes } from './constants';


function* list() {
    try {
        const response = yield call(assetListApi);
        console.log(response)
        const assets = response.data
        // NOTE - You can change this according to response format from your api
        yield put(authApiResponseSuccess(AssetActionTypes.LIST_ASSET, assets));
    } catch (error) {
        console.log(error)
        yield put(authApiResponseError(AssetActionTypes.LIST_ASSET, error));
    }
}

function* filter({ payload: { data } }) {
    try {
        console.log(data)
        const response = yield call(assetFilterApi, { data });
        const assets = response.data;

        yield put(authApiResponseSuccess(AssetActionTypes.FILTER_ASSET, assets));
    } catch (error) {
        yield put(authApiResponseError(AssetActionTypes.FILTER_ASSET, error));
    }
}

function* show({ payload: { data } }) {
    try {
        const response = yield call(assetShowApi, data);
        const asset = response.data.asset;

        yield put(authApiResponseSuccess(AssetActionTypes.SHOW_ASSET, asset));
    } catch (error) {
        yield put(authApiResponseError(AssetActionTypes.SHOW_ASSET, error));
    }
}

export function* watchAssetList(): any {
    yield takeEvery(AssetActionTypes.LIST_ASSET, list);
}

export function* watchAssetFilter(): any {
    yield takeEvery(AssetActionTypes.FILTER_ASSET, filter);
}

export function* watchAssetShow(): any {
    yield takeEvery(AssetActionTypes.SHOW_ASSET, show);
}


function* assetSaga(): any {
    yield all([
        fork(watchAssetList),
        fork(watchAssetShow),
        fork(watchAssetFilter)
    ]);
}

export default assetSaga;
