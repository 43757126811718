// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    login as loginApi,
    logout as logoutApi,
    forgotPassword as forgotPasswordApi,
    forgotPasswordConfirm as forgotPasswordConfirmApi,
} from '../../helpers/';

import { APICore, setAuthorization } from '../../helpers/api/apiCore';
import { authApiResponseSuccess, authApiResponseError } from './actions';
import { AuthActionTypes } from './constants';
import Cookies from 'universal-cookie';


const api = new APICore();

function* login({ payload: { email, password } }) {
    try {
        const response = yield call(loginApi, { email, password });
        const user = response.data.data;
        // NOTE - You can change this according to response format from your api
        api.setLoggedInUser(user);
        const cookies = new Cookies();
        cookies.set('myCat', 'Pacman', { path: '/' });
        setAuthorization(user['token']);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error));
        api.setLoggedInUser(null);
        setAuthorization(null);
    }
}

function* forgotPassword({ payload: { email } }) {
    try {

        yield call(forgotPasswordApi, {email});

        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, "Success"));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error));
    }
}

function* forgotPasswordChange({ payload: { reset_password_token, password, password_confirmation } }) {
    try {

         yield call(forgotPasswordConfirmApi, { reset_password_token, password, password_confirmation });

        yield put(authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD_CHANGE, "Success"));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error));
    }
}

function* logout() {
    try {
        yield call(logoutApi);
        api.setLoggedInUser(null);
        setAuthorization(null);
        yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
    } catch (error) {
        yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
    }
}

export function* watchLoginUser(): any {
    yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout(): any {
    yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchForgotPassword(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchForgotPasswordChange(): any {
    yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange);
}


function* authSaga(): any {
    yield all([
       fork(watchLoginUser),
        fork(watchLogout),
        fork(watchForgotPassword),
        fork(watchForgotPasswordChange)
    ]);
}

export default authSaga;
