import { DashboardActionTypes } from './constants';

const DASHBOARD_STATE = {
    upcoming_bookings: [],
    recent_bookings: [],
    event_suggestions: [],
    total_pages: 1,
    filters: { },
    loading: false,
};

type dashboardAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { dashboard?: {} | null, loading?: boolean, +value?: boolean };

const DashboardReducer = (state: State = DASHBOARD_STATE, action: dashboardAction): any => {
    switch (action.type) {
        case DashboardActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case DashboardActionTypes.LIST_DASHBOARD: {
                    return {
                        ...state,
                        upcoming_bookings: action.payload.data.upcoming_bookings,
                        event_suggestions: action.payload.data.event_suggestions,
                        recent_bookings: action.payload.data.recent_bookings,
                        total_pages: action.payload.data.total_pages,
                        loading: false,
                    };
                }
                default:
                    return {...state};
            }

        case DashboardActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case DashboardActionTypes.LIST_DASHBOARD: {
                    return {
                        ...state,
                        error: action.payload.error,
                        upcoming_events: [],
                        event_suggestions: [],
                        recent_bookings: [],
                        total_pages: 1,
                        loading: false,
                    };
                }
                default:
                    return {...state};
            }

        case DashboardActionTypes.LIST_DASHBOARD:
            return { ...state, loading: true, upcoming_events: [], event_suggestions: [], last_eventbooking: "", total_pages: 1 };
        default:
            return { ...state };
    }
};

export default DashboardReducer;