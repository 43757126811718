import { DashboardActionTypes } from './constants';

export const listDashboards = (actionType: string, data: any): DashboardAction => ({
    type: DashboardActionTypes.LIST_DASHBOARD,
    payload: { data },
});


// common success
export const authApiResponseSuccess = (actionType: string, data: any): DashboardAction => ({
    type: DashboardActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): DashboardAction => ({
    type: DashboardActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

