import { SurveyActionTypes } from './constants';

const SURVEY_STATE = {
    surveys: [],
    survey: {},
    total_pages: 1,
    filters: { },
    loading: false,
};

type SurveyAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { survey?: {} | null, loading?: boolean, +value?: boolean };

const SurveyReducer = (state: State = SURVEY_STATE, action: SurveyAction): any => {
    switch (action.type) {
        case SurveyActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case SurveyActionTypes.LIST_SURVEY: {
                    return {
                        ...state,
                        surveys: action.payload.data.surveys,
                        total_pages: action.payload.data.total_pages,
                        loading: false,
                    };
                }
                case SurveyActionTypes.SHOW_SURVEY: {
                    return {
                        ...state,
                        survey: action.payload.data,
                        loading: false,
                    };
                }
                case SurveyActionTypes.FILTER_SURVEY: {
                    return {
                        ...state,
                        surveys: action.payload.data.surveys,
                        total_pages: action.payload.data.total_pages,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case SurveyActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case SurveyActionTypes.LIST_SURVEY: {
                    return {
                        ...state,
                        error: action.payload.error,
                        surveys: [],
                        total_pages: 1,
                        loading: false,
                    };
                }
                case SurveyActionTypes.SHOW_SURVEY: {
                    return {
                        ...state,
                        error: action.payload.error,
                        survey: "",
                        loading: false,
                    };
                }
                case SurveyActionTypes.FILTER_SURVEY: {
                    return {
                        ...state,
                        surveys: action.payload.data,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case SurveyActionTypes.LIST_SURVEY:
            return { ...state, loading: true, surveys: [], total_pages: 1 };
        case SurveyActionTypes.SHOW_SURVEY:
            return { ...state, loading: true, survey: "" };
        case SurveyActionTypes.FILTER_SURVEY:
            return { ...state, loading: true, surveys: state.surveys, filters: state.filters };
        default:
            return { ...state };
    }
};

export default SurveyReducer;