import { APICore } from './apiCore';

const api = new APICore();

function eventbookingList(): any {
    const baseUrl = '/eventbookings';
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}


function eventbookingShow(params: any): any {
    const baseUrl = '/eventbookings/' + params;
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

function eventbookingCreateComment(requestBody) {
    const baseUrl = '/eventbookings/' + requestBody.id + '/eventbooking_comments';
    return api.create(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function eventbookingUpdateComment(requestBody) {
    const baseUrl = '/eventbookings/' + requestBody.eventbooking_id + '/eventbooking_comments/'+ requestBody.id;
    return api.update(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function eventbookingDeleteComment(requestBody) {
    const baseUrl = '/eventbookings/' + requestBody.eventbooking_id + '/eventbooking_comments/'+ requestBody.id;
    return api.delete(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function eventbookingFilter(params: any): any {
    const baseUrl = '/eventbookings';

    let new_query_array = []
    Object.keys(params["data"]).map(function(key) {
       new_query_array.push(`q[${key}]=${params["data"][key]}`)
    });

    return api.get(`${baseUrl}?${new_query_array.join('&')}`, params,{ headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

export { eventbookingList, eventbookingShow, eventbookingFilter, eventbookingCreateComment,  eventbookingUpdateComment, eventbookingDeleteComment };