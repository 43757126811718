import { APICore } from './apiCore';

const api = new APICore();

function eventCoachList(): any {
    const baseUrl = '/event_coaches';
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}


function eventCoachShow(params: any): any {
    const baseUrl = '/event_coaches/' + params;
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

function eventCoachUpdate(requestBody) {
    const baseUrl = '/event_coaches/' + requestBody.id;
    return api.update(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function eventCoachFilter(params: any): any {
    const baseUrl = '/event_coaches';

    let new_query_array = []
    Object.keys(params["data"]).map(function(key) {
        new_query_array.push(`q[${key}]=${params["data"][key]}`)
    });

    return api.get(`${baseUrl}?${new_query_array.join('&')}`, params,{ headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

export { eventCoachList, eventCoachShow, eventCoachFilter, eventCoachUpdate };