import { APICore } from './apiCore';

const api = new APICore();

function businessdocumentList(): any {
    const baseUrl = '/businessdocuments';
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}


function businessdocumentShow(params: any): any {
    const baseUrl = '/businessdocuments/' + params;
    return api.getWithoutQuery(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

function businessdocumentFilter(params: any): any {
    const baseUrl = '/businessdocuments';

    let new_query_array = []
    Object.keys(params["data"]).map(function(key) {
        new_query_array.push(`q[${key}]=${params["data"][key]}`)
    });

    return api.get(`${baseUrl}?${new_query_array.join('&')}`, params,{ headers: { 'Content-Type': 'application/json', Accept: "*/*", Authorization: `Bearer ${api.getLoggedInUser().token}` }});
}

export { businessdocumentList, businessdocumentShow, businessdocumentFilter };