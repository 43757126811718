// @flow
import { all } from 'redux-saga/effects';

import authSaga from './auth/saga';
import layoutSaga from './layout/saga';
import eventbookingSaga from "./pages/eventbookings/saga";
import eventSaga from "./pages/events/saga";
import dashboardSaga from "./pages/dashboards/saga";
import surveySaga from "./pages/surveys/saga";
import assetSaga from "./pages/assets/saga";
import digitalProductSagaSaga from "./pages/digital_products/saga";
import businessdocumentSaga from "./pages/businessdocuments/saga";
import bookingRequestSaga from "./pages/booking_requests/saga";
import eventCoachSaga from "./pages/event_coaches/saga";

export default function* rootSaga(): any {
    yield all([authSaga(), layoutSaga(), eventbookingSaga(), dashboardSaga(), surveySaga(), assetSaga(), eventSaga(), businessdocumentSaga(), digitalProductSagaSaga(), eventCoachSaga(), bookingRequestSaga()]);
}
