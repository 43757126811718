import { BookingRequestActionTypes } from './constants';

const BOOKING_REQUEST_STATE = {
    booking_requests: [],
    booking_request: {},
    total_pages: 1,
    confirmed_booking: false,
    filters: { },
    loading: false,
};

type BookingrequestAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { booking_request?: {} | null, loading?: boolean, +value?: boolean };

const BookingrequestReducer = (state: State = BOOKING_REQUEST_STATE, action: BookingrequestAction): any => {
    switch (action.type) {
        case BookingRequestActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BookingRequestActionTypes.LIST_BOOKING_REQUEST: {
                    return {
                        ...state,
                        booking_requests: action.payload.data.booking_requests,
                        total_pages: action.payload.data.total_pages,
                        confirmed_booking: false,
                        loading: false,
                    };
                }
                case BookingRequestActionTypes.SHOW_BOOKING_REQUEST: {
                    return {
                        ...state,
                        booking_request: action.payload.data,
                        confirmed_booking: action.payload.data.eventbookingstatus_key == "confirmed",
                        loading: false,
                    };
                }
                case BookingRequestActionTypes.CREATE_COMMENT: {
                    console.log(action.payload.data)
                    return {
                        ...state,
                        booking_request: action.payload.data.booking_request,
                        confirmed_booking: action.payload.data.booking_request.eventbookingstatus_key == "confirmed",
                        loading: false,
                    };
                }
                case BookingRequestActionTypes.CONFIRM_BOOKING_REQUEST: {
                    return {
                        ...state,
                        confirmed_booking: true,
                        loading: false,
                    };
                }
                case BookingRequestActionTypes.FILTER_BOOKING_REQUEST: {
                    return {
                        ...state,
                        booking_requests: action.payload.data.booking_requests,
                        total_pages: action.payload.data.total_pages,
                        filters: state.filters,
                        confirmed_booking: false,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case BookingRequestActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case BookingRequestActionTypes.LIST_BOOKING_REQUEST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        booking_requests: [],
                        total_pages: 1,
                        confirmed_booking: false,
                        loading: false,
                    };
                }
                case BookingRequestActionTypes.SHOW_BOOKING_REQUEST: {
                    return {
                        ...state,
                        error: action.payload.error,
                        booking_request: "",
                        confirmed_booking: false,
                        loading: false,
                    };
                }
                case BookingRequestActionTypes.FILTER_BOOKING_REQUEST: {
                    return {
                        ...state,
                        booking_requests: action.payload.data,
                        filters: state.filters,
                        confirmed_booking: false,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case BookingRequestActionTypes.LIST_BOOKING_REQUEST:
            return { ...state, loading: true,confirmed_booking: false, booking_requests: [], total_pages: 1 };
        case BookingRequestActionTypes.SHOW_BOOKING_REQUEST:
            return { ...state, loading: true, confirmed_booking: false, booking_request: "" };
        case BookingRequestActionTypes.FILTER_BOOKING_REQUEST:
            return { ...state, loading: true, confirmed_booking: false, booking_requests: state.booking_requests, filters: state.filters };
        default:
            return { ...state };
    }
};

export default BookingrequestReducer;