import { AssetActionTypes } from './constants';

const ASSET_STATE = {
    assets: [],
    asset: {},
    total_pages: 1,
    filters: { },
    loading: false,
};

type AssetAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { survey?: {} | null, loading?: boolean, +value?: boolean };

const AssetReducer = (state: State = ASSET_STATE, action: AssetAction): any => {
    switch (action.type) {
        case AssetActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AssetActionTypes.LIST_ASSET: {
                    return {
                        ...state,
                        assets: action.payload.data.assets,
                        total_pages: action.payload.data.total_pages,
                        loading: false,
                    };
                }
                case AssetActionTypes.SHOW_ASSET: {
                    return {
                        ...state,
                        asset: action.payload.data,
                        loading: false,
                    };
                }
                case AssetActionTypes.FILTER_ASSET: {
                    return {
                        ...state,
                        assets: action.payload.data.assets,
                        total_pages: action.payload.data.total_pages,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case AssetActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AssetActionTypes.LIST_ASSET: {
                    return {
                        ...state,
                        error: action.payload.error,
                        assets: [],
                        total_pages: 1,
                        loading: false,
                    };
                }
                case AssetActionTypes.SHOW_ASSET: {
                    return {
                        ...state,
                        error: action.payload.error,
                        asset: "",
                        loading: false,
                    };
                }
                case AssetActionTypes.FILTER_ASSET: {
                    return {
                        ...state,
                        assets: action.payload.data,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case AssetActionTypes.LIST_ASSET:
            return { ...state, loading: true, assets: [], total_pages: 1 };
        case AssetActionTypes.SHOW_ASSET:
            return { ...state, loading: true, asset: "" };
        case AssetActionTypes.FILTER_ASSET:
            return { ...state, loading: true, assets: state.assets, filters: state.filters };
        default:
            return { ...state };
    }
};

export default AssetReducer;