// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    eventCoachList as eventCoachListApi,
    eventCoachShow as eventCoachShowApi,
    eventCoachFilter as eventCoachFilterApi,
    eventCoachUpdate as eventCoachUpdateApi,
} from "../../../helpers/api/event_coach"

import {
    authApiResponseSuccess,
    authApiResponseError,
} from "./actions"
import { EventCoachActionTypes } from './constants';



function* list() {
    try {
        const response = yield call(eventCoachListApi);
        console.log(response)
        const event_coaches = response.data
        // NOTE - You can change this according to response format from your api
        yield put(authApiResponseSuccess(EventCoachActionTypes.LIST_EVENT_COACH, event_coaches));
    } catch (error) {
        console.log(error)
        yield put(authApiResponseError(EventCoachActionTypes.LIST_EVENT_COACH, error));
    }
}

function* filter({ payload: { data } }) {
    try {
        console.log(data)
        const response = yield call(eventCoachFilterApi, { data });
        const event_coaches = response.data;

        yield put(authApiResponseSuccess(EventCoachActionTypes.FILTER_EVENT_COACH, event_coaches));
    } catch (error) {
        yield put(authApiResponseError(EventCoachActionTypes.FILTER_EVENT_COACH, error));
    }
}

function* show({ payload: { data } }) {
    try {
        const response = yield call(eventCoachShowApi, data);
        const event_coach = response.data.event_coach;

        yield put(authApiResponseSuccess(EventCoachActionTypes.SHOW_EVENT_COACH, event_coach));
    } catch (error) {
        yield put(authApiResponseError(EventCoachActionTypes.SHOW_EVENT_COACH, error));
    }
}

function* update(action) {

    try {
       const response = yield call(eventCoachUpdateApi, {id: action.payload.id, data: action.payload.data});
       console.log(response.data.data)

        yield put(authApiResponseSuccess(EventCoachActionTypes.UPDATE_EVENT_COACH, {event_coach: response.data.data.event_coach, id: action.payload.data }));
    } catch (error) {
        yield put(authApiResponseError(EventCoachActionTypes.UPDATE_EVENT_COACH, error));
    }
}

export function* watchEventCoachList(): any {
    yield takeEvery(EventCoachActionTypes.LIST_EVENT_COACH, list);
}

export function* watchEventCoachFilter(): any {
    yield takeEvery(EventCoachActionTypes.FILTER_EVENT_COACH, filter);
}

export function* watchEventCoachShow(): any {
    yield takeEvery(EventCoachActionTypes.SHOW_EVENT_COACH, show);
}

export function* watchEventCoachUpdate(): any {
    yield takeEvery(EventCoachActionTypes.UPDATE_EVENT_COACH, update);
}


function* eventCoachSaga(): any {
    yield all([
        fork(watchEventCoachList),
        fork(watchEventCoachShow),
        fork(watchEventCoachFilter),
        fork(watchEventCoachUpdate)
    ]);
}

export default eventCoachSaga;
