// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';
import { select } from 'redux-saga/effects'
import {
    bookingRequestList as bookingRequestListApi,
    bookingRequestShow as bookingRequestShowApi,
    bookingRequestFilter as bookingRequestFilterApi,
    bookingRequestUpdate as bookingRequestUpdateApi,
    bookingRequestCreateComment as bookingRequestCreateCommentApi,
} from "../../../helpers/api/booking_request"

import {
    authApiResponseSuccess,
    authApiResponseError,
} from "./actions"
import { BookingRequestActionTypes } from './constants';


function* list() {
    try {

        const response = yield call(bookingRequestListApi);
        const booking_requests = response.data
        // NOTE - You can change this according to response format from your api
        yield put(authApiResponseSuccess(BookingRequestActionTypes.LIST_BOOKING_REQUEST, booking_requests));
    } catch (error) {
        yield put(authApiResponseError(BookingRequestActionTypes.LIST_BOOKING_REQUEST, error));
    }
}

function* filter({ payload: { data } }) {
    try {
        const response = yield call(bookingRequestFilterApi, { data });
        const booking_requests = response.data;

        yield put(authApiResponseSuccess(BookingRequestActionTypes.FILTER_BOOKING_REQUEST, booking_requests));
    } catch (error) {
        yield put(authApiResponseError(BookingRequestActionTypes.FILTER_BOOKING_REQUEST, error));
    }
}

function* show({ payload: { data } }) {
    try {
        const response = yield call(bookingRequestShowApi, data);
        console.log(response)
        const booking_request = response.data.eventbooking;

        yield put(authApiResponseSuccess(BookingRequestActionTypes.SHOW_BOOKING_REQUEST, booking_request));
    } catch (error) {
        yield put(authApiResponseError(BookingRequestActionTypes.SHOW_BOOKING_REQUEST, error));
    }
}

function* createComment(action) {

    try {
        const response = yield call(bookingRequestCreateCommentApi, {id: action.payload.id, data: action.payload.data});

        yield put(authApiResponseSuccess(BookingRequestActionTypes.CREATE_COMMENT, {booking_request: response.data.data.eventbooking, id: action.payload.data }));
    } catch (error) {
        yield put(authApiResponseError(BookingRequestActionTypes.CREATE_COMMENT, error));
    }
}

function* update(action) {
    const items = yield select();

    try {
        yield call(bookingRequestUpdateApi, {id: action.payload.id, data: action.payload.data});

        yield put(authApiResponseSuccess(BookingRequestActionTypes.CONFIRM_BOOKING_REQUEST, {booking_requests: items.BookingrequestReducer.booking_requests, id: action.payload.data }));
    } catch (error) {
        yield put(authApiResponseError(BookingRequestActionTypes.CONFIRM_BOOKING_REQUEST, error));
    }
}

export function* watchbookingRequestList(): any {
    yield takeEvery(BookingRequestActionTypes.LIST_BOOKING_REQUEST, list);
}

export function* watchbookingRequestFilter(): any {
    yield takeEvery(BookingRequestActionTypes.FILTER_BOOKING_REQUEST, filter);
}

export function* watchbookingRequestShow(): any {
    yield takeEvery(BookingRequestActionTypes.SHOW_BOOKING_REQUEST, show);
}

export function* watchbookingRequestCreateComment(): any {
    yield takeEvery(BookingRequestActionTypes.CREATE_COMMENT, createComment);
}

export function* watchbookingRequestUpdate() {
    yield takeEvery(BookingRequestActionTypes.CONFIRM_BOOKING_REQUEST, update);
}


function* bookingRequestSaga(): any {
    yield all([
        fork(watchbookingRequestList),
        fork(watchbookingRequestShow),
        fork(watchbookingRequestFilter),
        fork(watchbookingRequestUpdate),
        fork(watchbookingRequestCreateComment)
    ]);
}

export default bookingRequestSaga;
