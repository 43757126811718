import { DigitalProductActionTypes } from './constants';

export const listDigitalProducts = (actionType: string, data: any): DigitalProductAction => ({
    type: DigitalProductActionTypes.LIST_DIGITAL_PRODUCT,
    payload: { data },
});

export const filterDigitalProducts = (data: any): DigitalProductAction => ({
    type: DigitalProductActionTypes.FILTER_DIGITAL_PRODUCT,
    payload: { data },
});

export const showDigitalProducts = (data: any): DigitalProductAction => ({
    type: DigitalProductActionTypes.SHOW_DIGITAL_PRODUCT,
    payload: { data },
});

// common success
export const authApiResponseSuccess = (actionType: string, data: any): DigitalProductAction => ({
    type: DigitalProductActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): DigitalProductAction => ({
    type: DigitalProductActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

