import { EventActionTypes } from './constants';

const EVENT_STATE = {
    events: [],
    event: {},
    total_pages: 1,
    filters: { },
    loading: false,
};

type EventAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { event?: {} | null, loading?: boolean, +value?: boolean };

const EventReducer = (state: State = EVENT_STATE, action: EventAction): any => {
    switch (action.type) {
        case EventActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case EventActionTypes.LIST_EVENT: {
                    return {
                        ...state,
                        events: action.payload.data.events,
                        total_pages: action.payload.data.total_pages,
                        loading: false,
                    };
                }
                case EventActionTypes.SHOW_EVENT: {
                    return {
                        ...state,
                        event: action.payload.data,
                        loading: false,
                    };
                }
                case EventActionTypes.FILTER_EVENT: {
                    return {
                        ...state,
                        events: action.payload.data.events,
                        total_pages: action.payload.data.total_pages,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case EventActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case EventActionTypes.LIST_EVENT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        events: [],
                        total_pages: 1,
                        loading: false,
                    };
                }
                case EventActionTypes.SHOW_EVENT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        event: "",
                        loading: false,
                    };
                }
                case EventActionTypes.FILTER_EVENT: {
                    return {
                        ...state,
                        events: action.payload.data,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case EventActionTypes.LIST_EVENT:
            return { ...state, loading: true, events: [], total_pages: 1 };
        case EventActionTypes.SHOW_EVENT:
            return { ...state, loading: true, event: "" };
        case EventActionTypes.FILTER_EVENT:
            return { ...state, loading: true, events: state.events, filters: state.filters };
        default:
            return { ...state };
    }
};

export default EventReducer;