import { EventCoachActionTypes } from './constants';
import {BookingRequestActionTypes} from "../booking_requests/constants";

const EVENT_COACH_STATE = {
    event_coaches: [],
    event_coach: {},
    total_pages: 1,
    filters: { },
    loading: false,
};

type EventCoachAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { event?: {} | null, loading?: boolean, +value?: boolean };

const EventCoachReducer = (state: State = EVENT_COACH_STATE, action: EventCoachAction): any => {
    switch (action.type) {
        case EventCoachActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case EventCoachActionTypes.LIST_EVENT_COACH: {
                    return {
                        ...state,
                        event_coaches: action.payload.data.event_coaches,
                        total_pages: action.payload.data.total_pages,
                        loading: false,
                    };
                }
                case EventCoachActionTypes.SHOW_EVENT_COACH: {
                    return {
                        ...state,
                        event_coach: action.payload.data,
                        loading: false,
                    };
                }
                case EventCoachActionTypes.UPDATE_EVENT_COACH: {
                    return {
                        ...state,
                        event_coach: action.payload.data.event_coach,
                        loading: false,
                    };
                }
                case EventCoachActionTypes.FILTER_EVENT_COACH: {
                    return {
                        ...state,
                        event_coaches: action.payload.data.event_coaches,
                        total_pages: action.payload.data.total_pages,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case EventCoachActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case EventCoachActionTypes.LIST_EVENT_COACH: {
                    return {
                        ...state,
                        error: action.payload.error,
                        event_coaches: [],
                        total_pages: 1,
                        loading: false,
                    };
                }
                case EventCoachActionTypes.SHOW_EVENT_COACH: {
                    return {
                        ...state,
                        error: action.payload.error,
                        event_coach: "",
                        loading: false,
                    };
                }
                case EventCoachActionTypes.UPDATE_EVENT_COACH: {
                    return {
                        ...state,
                        event_coach: "",
                        loading: false,
                    };
                }
                case EventCoachActionTypes.FILTER_EVENT_COACH: {
                    return {
                        ...state,
                        event_coaches: action.payload.data,
                        filters: state.filters,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }

        case EventCoachActionTypes.LIST_EVENT_COACH:
            return { ...state, loading: true, event_coaches: [], total_pages: 1 };
        case EventCoachActionTypes.SHOW_EVENT_COACH:
            return { ...state, loading: true, event_coach: "" };
        case EventCoachActionTypes.UPDATE_EVENT_COACH:
            return { ...state, loading: true, event_coach: "" };
        case EventCoachActionTypes.FILTER_EVENT_COACH:
            return { ...state, loading: true, event_coaches: state.event_coaches, filters: state.filters };
        default:
            return { ...state };
    }
};

export default EventCoachReducer;