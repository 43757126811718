// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    digitalProductList as digitalProductListApi,
    digitalProductShow as digitalProductShowApi,
    digitalProductFilter as digitalProductFilterApi
} from "../../../helpers/api/digital_product"

import {
    authApiResponseSuccess,
    authApiResponseError,
} from "./actions"
import { DigitalProductActionTypes } from './constants';


function* list() {
    try {
        const response = yield call(digitalProductListApi);
        console.log(response)
        const assets = response.data
        // NOTE - You can change this according to response format from your api
        yield put(authApiResponseSuccess(DigitalProductActionTypes.LIST_DIGITAL_PRODUCT, assets));
    } catch (error) {
        console.log(error)
        yield put(authApiResponseError(DigitalProductActionTypes.LIST_DIGITAL_PRODUCT, error));
    }
}

function* filter({ payload: { data } }) {
    try {
        console.log(data)
        const response = yield call(digitalProductFilterApi, { data });
        const assets = response.data;

        yield put(authApiResponseSuccess(DigitalProductActionTypes.FILTER_DIGITAL_PRODUCT, assets));
    } catch (error) {
        yield put(authApiResponseError(DigitalProductActionTypes.FILTER_DIGITAL_PRODUCT, error));
    }
}

function* show({ payload: { data } }) {
    try {
        const response = yield call(digitalProductShowApi, data);
        const asset = response.data.asset;

        yield put(authApiResponseSuccess(DigitalProductActionTypes.SHOW_DIGITAL_PRODUCT, asset));
    } catch (error) {
        yield put(authApiResponseError(DigitalProductActionTypes.SHOW_DIGITAL_PRODUCT, error));
    }
}

export function* watchDigitalProductList(): any {
    yield takeEvery(DigitalProductActionTypes.LIST_DIGITAL_PRODUCT, list);
}

export function* watchDigitalProductFilter(): any {
    yield takeEvery(DigitalProductActionTypes.FILTER_DIGITAL_PRODUCT, filter);
}

export function* watchDigitalProductShow(): any {
    yield takeEvery(DigitalProductActionTypes.SHOW_DIGITAL_PRODUCT, show);
}


function* digitalProductSaga(): any {
    yield all([
        fork(watchDigitalProductList),
        fork(watchDigitalProductShow),
        fork(watchDigitalProductFilter)
    ]);
}

export default digitalProductSaga;
