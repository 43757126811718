// @flow
import { all, fork, put, takeEvery, call } from 'redux-saga/effects';

import {
    surveyList as surveyListApi,
    surveyShow as surveyShowApi,
    surveyFilter as surveyFilterApi
} from "../../../helpers/api/survey"

import {
    authApiResponseSuccess,
    authApiResponseError,
} from "./actions"
import { SurveyActionTypes } from './constants';


function* list() {
    try {
        const response = yield call(surveyListApi);
        console.log(response)
        const surveys = response.data
        // NOTE - You can change this according to response format from your api
        yield put(authApiResponseSuccess(SurveyActionTypes.LIST_SURVEY, surveys));
    } catch (error) {
        console.log(error)
        yield put(authApiResponseError(SurveyActionTypes.LIST_SURVEY, error));
    }
}

function* filter({ payload: { data } }) {
    try {
        console.log(data)
        const response = yield call(surveyFilterApi, { data });
        const surveys = response.data;

        yield put(authApiResponseSuccess(SurveyActionTypes.FILTER_SURVEY, surveys));
    } catch (error) {
        yield put(authApiResponseError(SurveyActionTypes.FILTER_SURVEY, error));
    }
}

function* show({ payload: { data } }) {
    try {
        const response = yield call(surveyShowApi, data);
        const survey = response.data.survey;

        yield put(authApiResponseSuccess(SurveyActionTypes.SHOW_SURVEY, survey));
    } catch (error) {
        yield put(authApiResponseError(SurveyActionTypes.SHOW_SURVEY, error));
    }
}

export function* watchSurveyList(): any {
    yield takeEvery(SurveyActionTypes.LIST_SURVEY, list);
}

export function* watchSurveyFilter(): any {
    yield takeEvery(SurveyActionTypes.FILTER_SURVEY, filter);
}

export function* watchSurveyShow(): any {
    yield takeEvery(SurveyActionTypes.SHOW_SURVEY, show);
}


function* surveySaga(): any {
    yield all([
        fork(watchSurveyList),
        fork(watchSurveyShow),
        fork(watchSurveyFilter)
    ]);
}

export default surveySaga;
